import { getLogin } from "../../network/network.js"
import Form from "../common/forms/form.js"

export default function LoginForm({ onSubmitResponse, config }) {
  function handleSubmitResponse(data) {
    onSubmitResponse?.(data)
  }
  return (
    <>
      <Form
        formConfig={config}
        primaryAction={<span className="m-1">Sign In</span>}
        isSingleFullButton={true}
        formBottomText={
          <span>
            Don't have an account?{" "}
            <a href="/signup" className="link-blue">
              Signup
            </a>
          </span>
        }
        submitFunction={getLogin}
        onSubmitResponse={handleSubmitResponse}
        forceActivePrimaryButton={true}
      />
    </>
  )
}
