import Seo from "@common/seo"
import { getLayout } from "../components/layouts/landingSimpleLayout"
import Logo from "../components/common/logo"
import { getIsLoggedIn } from "../network/network.js"
import { THEME } from "../configs/theme.js"
import AuthComponent from "../components/auth/authComponent"
import { useTheme } from "next-themes"
import { LoginTypeEnum } from "components/common/types"
import { VerticalCenterContainer } from "@common/container"

Login.getLayout = getLayout

const loginType = THEME["loginType"] || [LoginTypeEnum.Login]

Login.getInitialProps = async (ctx) => {
  const cookie = ctx.req
    ? { withCredentials: true, headers: { cookie: ctx.req.headers.cookie } }
    : {}

  return await getIsLoggedIn(cookie)
}

export default function Login({ data }) {
  const { theme } = useTheme()
  return (
    <>
      <Seo title={`Login - ${THEME["site"]}`} />
      <VerticalCenterContainer minusValue={204}>
        <Logo
          logoObj={THEME["logo"]}
          version="icon"
          className="h-12 mx-auto mb-8 w-auto"
          isDarkMode={theme == "dark"}
          url="/"
        />
        <div className="sm:mx-auto sm:w-full sm:max-w-lg  border  rounded-md p-10  sm:px-14">
          <AuthComponent data={data} loginType={loginType} />
        </div>
      </VerticalCenterContainer>
    </>
  )
}
