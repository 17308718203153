/* eslint-disable no-undef */
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import { gLogin, updateAccountDetails, useIsLoggedIn } from "network/network"
import { THEME } from "configs/theme"
import { useToast } from "components/common/toast/toastProvider"
import { hitUrl, isNullOrEmpty } from "components/common/lib/util"
import { Status } from "@common/types"

// ☢️ Using your own button is not supported, since there is no API to initiate the button flow when your button is clicked.
//     https://developers.google.com/identity/sign-in/web/build-button
//     https://developers.google.com/identity/gsi/web/guides/offerings
export default function GoogleLogin() {
  const router = useRouter()
  const { showPromiseToast } = useToast()
  const { data } = useIsLoggedIn("google_oauth")

  useEffect(() => {
    if (data) handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  function handleInit() {
    google?.accounts.id?.initialize({
      client_id: data.google_client_id,
      callback: handleGoogleLogin,
    })
    google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
      //https://developers.google.com/identity/gsi/web/reference/js-reference
      text: "continue_with",
      type: "standard",
      size: "large",
    })
  }

  const handleGoogleLogin = async (response) => {
    const query = new URLSearchParams(response || {}).toString()
    const { data, status } = await showPromiseToast(gLogin({ query }), {
      error: "Login Failed. Try again!",
      pending: "Logging In",
      success: "Logged In Successfully!",
    })
    if (!isNullOrEmpty(data) && status == Status.Success) {
      handleOnSubmit()
      let home_path
      if (data.home_path) ({ home_path } = data)
      else {
        home_path = THEME["homePath"]
        updateAccountDetails({ home_path })
      }
      router.push(home_path)
    }
  }

  function handleOnSubmit() {
    const email = encodeURIComponent("google_signin")
    const site = encodeURIComponent(THEME["site"])
    // 🪧 same form URL used for login and signup
    const url = `https://docs.google.com/forms/d/e/1FAIpQLScGALxdR8fehXEr7TJP-9eIO6NIwBzKF1kUUOkGvT7SF6JC7w/formResponse?usp=pp_url&entry.2039695576=${null}&entry.1824555110=${email}&entry.615213946=${null}&entry.1738298124=${"login"}&entry.11348956=${site}`
    hitUrl(url)
  }

  return (
    <div
      id="signUpDiv"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
      data-text="signup_with"
    />
  )
}
