import React, { useEffect } from "react"
import LoginForm from "./loginForm"
import GoogleLogin from "./googleLogin"
import GoogleLoginForm from "./googleLoginForm"
import Magiclink from "./magicLink"
import { THEME } from "../../configs/theme"
import { useRouter } from "next/router"
import Loader from "@common/loader"
import OtpForm from "../otpForm"
import { useToast } from "components/common/toast/toastProvider"
import SeparatorText from "components/common/separatorText"
import { LoginTypeEnum, Status } from "components/common/types"
import { updateAccountDetails } from "network/network"
import { Header } from "@common/header/headerUnit"
import { getHomePath } from "lib/util"
import { LabelProps } from "@common/forms/label"
import ForgotPassword from "components/auth/forgotPassword"

interface AuthComponentProps {
  data: any
  loginType: LoginTypeEnum[]
  status: Status
}
//TODO:  move these FormField interfaces to form, when it moves to tsx.
type FieldConfig = Pick<LabelProps, "required" | "id"> & {
  field: string
  type: string
  label?: LabelProps
  value?: string
  renderCustom?: () => React.ReactNode
}

interface Config {
  [key: string]: {
    [key: string]: FieldConfig
  }
}

// 📌 [TO DO]: Get access to "data" in this component itself. (HINT: cookie accessed RN in server side)
export default function AuthComponent({ data, loginType }: AuthComponentProps) {
  const router = useRouter()
  const { showToast } = useToast()

  useEffect(() => {
    //this is the pre-login check. If logged in, user is redirected to dashboard directly
    if (data) {
      if (data.status == Status.Success && "home_path" in data && data.home_path) {
        router.push(data.home_path)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    router?.query?.reset &&
      showToast("Password updated successfully!", {
        type: "success",
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const configs: Config = {
    [LoginTypeEnum.Login]: {
      account_id: {
        field: "input",
        type: "email",
        label: {
          label: "Work Email",
        },
        value: "",
        required: true,
      },
      password: {
        field: "input",
        type: "password",
        label: {
          label: <ForgotPassword/>,
        },
        value: "",
      },
    },
    [LoginTypeEnum.Magiclink]: {
      email: {
        field: "input",
        type: "email",
        label: {
          label: "Work Email",
        },
        value: "",
        required: true,
      },
    },
    [LoginTypeEnum.GoogleLogin]: {
      custom: {
        type: "custom",
        field: "custom",
        renderCustom: () => <GoogleLogin />,
      },
    },
  }

  function handleLoginSubmitResponse(data: any) {
    if (!data) return
    const { status, home_path, cust_id } = data
    if (status == "SUCCESS") {
      let url
      const configHomePath = getHomePath(cust_id)
      if (home_path) {
        url = router.query.url || home_path
      } else {
        url = router.query.url || configHomePath
        updateAccountDetails({ home_path: configHomePath })
      }
      router.push(decodeURIComponent(url))
    } else if (status == "OTP_PENDING") {
      const url = new URL(window.location.href)
      url.searchParams.set("otp", "true")
      router.push(url.href)
    }
  }

  if (!data) {
    return (
      <div className="h-full w-full">
        <Loader />
      </div>
    )
  }

  if (router && "query" in router && "otp" in router.query) {
    return <OtpForm onSubmitResponse={handleLoginSubmitResponse} />
  }

  return (
    <>
      {loginType?.map((name, index) => {
        switch (name) {
          case LoginTypeEnum.Login:
            return (
              <>
                <div className="my-2">
                  <Header.MainHeader variant="h6" alignment="center">
                    <p className="mb-6">Sign in to your account</p>
                  </Header.MainHeader>
                </div>
                <LoginForm
                  key={index}
                  onSubmitResponse={handleLoginSubmitResponse}
                  config={configs[name]}
                />
                {renderSeparator(index)}
              </>
            )
          case LoginTypeEnum.GoogleLogin:
            return (
              <>
                <GoogleLoginForm key={index} config={configs[name]} />
                {renderSeparator(index)}
              </>
            )
          case LoginTypeEnum.Magiclink:
            return (
              <>
                <Magiclink
                  key={index}
                  onSubmitResponse={handleLoginSubmitResponse}
                  config={configs[name]}
                  contactEmail={THEME["contactEmail"]}
                />
                {renderSeparator(index)}
              </>
            )
          default:
            return null
        }
      })}
    </>
  )

  function renderSeparator(index: any) {
    return (
      index < loginType.length - 1 && (
        <div className="my-4">
          <SeparatorText />
        </div>
      )
    )
  }
}
